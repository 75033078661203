import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'grommet';

import Layout from '../components/layout/layout';
import PortableText from '../components/portable-text/portable-text';
import Hero from '../components/hero/hero';
import Marker from '../components/marker/marker';
import Lede from '../components/lede/lede';
import Byline from '../components/byline/byline';
import Icon from '../components/icon';
import CenterColumn from '../components/center-column/center-column';

export const query = graphql`
  query ArticleTemplateQuery($id: String!) {
    article: sanityArticle(id: { eq: $id }) {
      id
      title
      subtitle
      lede
      publishedAt(formatString: "M/D/YYYY")
      authors {
        author {
          name
        }
      }
      mainImage {
        ...CaptionedImage
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
    }
  }
`;

const ArticlePage = ({ data, pageContext }) => {
  const { lede, title, _rawBody, mainImage, publishedAt, authors, subtitle } =
    data.article;

  return (
    <Layout>
      <Hero mainImage={mainImage} title={title} subtitle={subtitle}></Hero>
      <Byline date={publishedAt} author={authors[0].author.name}>
        <Marker text="Article" />
      </Byline>

      {lede && <Lede text={lede} />}
      <CenterColumn>
        {_rawBody && <PortableText blocks={_rawBody} />}
        <Box margin={{ vertical: 'xlarge' }}>
          <Box alignSelf="center">
            <Icon symbol={'minecraft'} />
          </Box>
        </Box>
      </CenterColumn>
    </Layout>
  );
};

export default ArticlePage;
