import React from 'react';

const MinecraftIcon = () => (
  <svg height="25" version="1.1" viewBox="0 0 50 50" width="25px">
    <title />
    <desc />
    <g fill="none" id="minecraft_squircle" stroke="none">
      <path
        d="M6,0 L44,0 C47.3137085,0 50,2.6862915 50,6 L50,44 C50,47.3137085 47.3137085,50 44,50 L6,50 C2.6862915,50 0,47.3137085 0,44 L0,6 C0,2.6862915 2.6862915,0 6,0 Z"
        fill="#12B346"
        id="Background"
      />
      <g fill="#000000" id="Group" transform="translate(6.000000, 6.000000)">
        <path
          d="M25.0274658,19 L31.6866576,19 L31.6866576,38 L25.0274658,38 L25,32.0345459 L13.2390383,32.0345459 L13.2390383,38 L6.59789531,38 L6.59789531,19 L13,19 L13,13 L25,13 L25.0274658,19 Z M25.0455146,0.426870837 L37.5898958,0.426870837 L37.5898958,12.971252 L25.0455146,12.971252 L25.0455146,0.426870837 Z M0.694657122,0.426870837 L13.2390383,0.426870837 L13.2390383,12.971252 L0.694657122,12.971252 L0.694657122,0.426870837 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default MinecraftIcon;
