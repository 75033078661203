import React from 'react';

import { Box, Text } from 'grommet';

const Byline = ({ author, date, children }) => (
  <Box
    width="medium"
    alignSelf="center"
    align="center"
    margin={{ top: 'large' }}
  >
    <Text textAlign="center" size="small" margin={{ bottom: 'small' }}>
      {`By ${author} | ${date}`}
    </Text>
    {children}
  </Box>
);

export default Byline;
