import React from 'react';
import HamburgerIcon from './hamburger';
import MinecraftIcon from './minecraft-icon';

function Icon(props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />;
    case 'minecraft':
      return <MinecraftIcon />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
